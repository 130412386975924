import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import Header from "components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAddKaryawan,
  fetchDeleteKaryawan,
  fetchListCabang,
  fetchListKaryawan,
} from "state/karyawanActions";
import { Delete, Edit, Groups2Outlined } from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import FlexBetween from "components/FlexBetween";
import AddKaryawanModal from "components/Modals/AddKaryawanModal";
import FloatingAlert from "components/FloatingAlert";
import { setMessage, setStatus } from "state";
import KonfirmasiModal from "components/Modals/KonfirmasiModal";

const Karyawan = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { list_karyawan, list_cabang } = useSelector((state) => state.karyawan);
  const { status, message } = useSelector((state) => state.global);
  const [openAdd, setOpenAdd] = useState(false);
  const [openKonfirmasi, setOpenKonfirmasi] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    dispatch(fetchListKaryawan());
    dispatch(fetchListCabang());
  }, []);

  const handleCloseSnackbar = () => {
    dispatch(setStatus("default"));
    dispatch(setMessage(""));
    dispatch(fetchListKaryawan());
  };

  const handleSelectedData = (id) => {
    let currentSelectedData = list_karyawan.find(
      (item) => item.id_karyawan === id
    );
    setSelectedData(currentSelectedData);
  };

  const handleSubmitAddKaryawan = (value) => {
    const reqData = {
      nik: value.nik,
      nama_karyawan: value.nama,
      email: value.email,
      jenis_kelamin: value.jenis_kelamin,
      jabatan: value.jabatan,
      lokasi_kerja: value.lokasi_kerja,
      no_hp: value.no_hp,
      password: value.password,
      role: value.role,
      tipe_absen: value.tipe_absen,
    };
    dispatch(fetchAddKaryawan(reqData));
  };

  const handleDelete = () => {
    dispatch(fetchDeleteKaryawan(selectedData.id_karyawan));
    setOpenKonfirmasi(false);
    setSelectedData(null);
  };
  const columnsKaryawan = [
    {
      name: "id_karyawan",
      label: "ID Karyawan",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nik",
      label: "NIK",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "nama_karyawan",
      label: "Nama",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "no_hp",
      label: "Nomor HP",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "jabatan",
      label: "Jabatan",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "lokasi_kerja",
      label: "Lokasi Toko",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "aksi",
      label: "Aksi",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <IconButton color="info" onClick={() => {}}>
                <Edit />
              </IconButton>
              <IconButton
                color="error"
                onClick={() => {
                  setOpenKonfirmasi(true);
                  handleSelectedData(id);
                }}
              >
                <Delete />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    filterType: "dropdown",
    fixedHeader: false,
    fixedSelectColumn: false,
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="Karyawan" subtitle="Daftar karyawan perusahaan" />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              "&:hover": {
                backgroundColor: theme.palette.secondary.light, // Tetap sama dengan warna awal
              },
            }}
            onClick={() => setOpenAdd(true)}
          >
            <Groups2Outlined sx={{ mr: "10px" }} />
            Tambah Karyawan
          </Button>
        </Box>
      </FlexBetween>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <MUIDataTable
          title={""}
          data={list_karyawan}
          columns={columnsKaryawan}
          options={options}
        />
        <AddKaryawanModal
          open={openAdd}
          onClose={() => setOpenAdd(false)}
          onSubmit={(value) => handleSubmitAddKaryawan(value)}
        />
        <KonfirmasiModal
          titleType={"Hapus"}
          subtitleType={"Menghapus"}
          dataWillProses={selectedData?.id_karyawan}
          open={openKonfirmasi}
          onClose={() => setOpenKonfirmasi(false)}
          onSubmit={() => handleDelete()}
        />
      </Box>
      <FloatingAlert
        open={message.length > 0}
        onClose={handleCloseSnackbar}
        message={message}
        severity={status === "failed" ? "error" : status}
      />
    </Box>
  );
};

export default Karyawan;
